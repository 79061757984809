import { ITheme } from '@/shared/styles/MuiTheme'
import { makeStyles } from '@material-ui/core/styles'

export const overviewTableStyles = makeStyles((theme: ITheme) => ({
    root: {
        position: 'relative',
        '&--media-md': {
            '& $wrapper': {
                overflow: 'auto',
            },
            '& $overviewTable': {
                width: '100%',    
            },
            '& $overviewTableRowCell': {
                padding: '5px 4px 4px 0',
                '&.sticky-col': {
                    zIndex: 1,
                },
                '&:first-of-type': {
                    width: '25px',
                    minWidth: '25px',
                    left: 0,
                },
                '&.nameColumn': {
                    left: '25px',
                    '& > div > p > div': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }
                },
                '&.nameColumnFlf': {
                    left: '25px',
                    maxWidth: 250,
                    '& > div > p > div': {
                        whiteSpace: 'unset !important'
                    }
                },
                [theme.breakpoints.down('md')]: {
                    '&.nameColumnFlf': {
                        maxWidth: 150,
                    }
                },
                [theme.breakpoints.down('sm')]: {
                    '&.nameColumnFlf': {
                        maxWidth: 130,
                    }
                },
                '&--extended': {
                    width: '25px',
                    minWidth: '25px',
                    '& > div': {
                        justifyContent: 'flex-start',
                    }
                },
            }
        },
        '&--no-background': {
            '& $overviewTableRowCell, & $overviewTableRow': {
                background: 'none !important',
                border: 'unset !important',
            }
        },
        '&--cell-white-background': {
            '& $overviewTableRowCell': {
                '&.sticky-col': {
                }
            }
        }
    },
    wrapper: {
        [theme.breakpoints.down('md')]: {
            overflow: 'auto',

            '& $overviewTable': {
                width: '100%',
                '&--edit': {
                    '& $overviewTableRow': {
                        '&--expanded': {
                            '& $overviewTableRowCell': {
                                height: '101px',
                            }
                        }
                    },
                    '& $overviewTableRowCell': {
                        height: '61px',
                    }
                }
            },
            '& $overviewTableRow': {
                '&:nth-of-type(2n)': {
                    '& $overviewTableRowCell': {
                        '&.sticky-col': {
                        }
                    }
                }
            },
            '& $overviewTableRowCell': {
                '&.sticky-col': {
                    zIndex: 1,
                },
                '&:first-of-type': {
                    width: '30px',
                    left: 0,
                },
                '&.nameColumn': {
                    left: '25px',
                }
            }
        }
    },
    overviewTable: {
        width: '100%',
        borderCollapse: 'collapse',
        '& *': {
            maxWidth: '100%',
        },
        '&--edit': {
            '& $overviewTableRowCell': {
                paddingRight: '0.25rem',
                paddingLeft: '0.25rem',
            }
        }
    },
    overviewTableRow: {
        '&, & $overviewTableRowCell': {
        },
        '&:nth-of-type(2n)': {
            '&, & $overviewTableRowCell': {
            }
        },
        '&--expanded': {
            '& $overviewTableRowCell': {
                paddingBottom: '5rem',
            }
        }
    },
    overviewTableRowCell: {
        minWidth: '35px',
        // width: '20px',
        padding: '1rem 0.5rem',
        '&.nameColumn': {
            position: 'sticky',
            '& > div': {
                justifyContent: 'flex-start',
            }
        },
        '&.positionColumn': {
            position: 'sticky'
        },
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&.body': {
            backgroundColor: theme.props.sectionMode.background,
        },
        '&.header': {
            backgroundColor:  theme.props.sectionMode.card,
        },
        '&.bodyDarkBackground': {
            backgroundColor: theme.props.sectionMode.card,
        },
    },
    header: {
        borderRadius: theme.spacing(2, 2, 0, 0),
        backgroundColor:  theme.props.sectionMode.card,
    }
}))